import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormioModule } from 'angular-formio';

import { NgxSpinnerModule } from "ngx-spinner";


/* Firebase services + enviorment module */
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { CreateFormService } from './shared/create-form.service';

/* Auth service */
import { AuthenticationService } from './shared/auth.service';
import { AuthGuard } from './shared/auth.guard';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BuilderComponent } from './builder/builder.component';
import { FormsComponent } from './forms/forms.component';
import { NavComponent } from './nav/nav.component';
import { ViewSubmissionComponent } from './forms/view-submission/view-submission.component';


const Formio = require('formiojs').Formio;
Formio.icons = 'fontawesome';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BuilderComponent,
    FormsComponent,
    NavComponent,
    ViewSubmissionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FormioModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxSpinnerModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    CreateFormService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
