// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDhglSq01JYtRjSvA1jofrYIvZfJG_fCnQ",
    authDomain: "e20db-9a186.firebaseapp.com",
    databaseURL: "https://e20db-9a186.firebaseio.com",
    projectId: "e20db-9a186",
    storageBucket: "e20db-9a186.appspot.com",
    messagingSenderId: "314387218179",
    appId: "1:314387218179:web:b4d501c69e8b3694"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
