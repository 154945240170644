import { Component, OnInit } from '@angular/core';


import Swal from 'sweetalert2';



@Component({
  selector: 'app-view-submission',
  templateUrl: './view-submission.component.html',
  styleUrls: ['./view-submission.component.scss']
})
export class ViewSubmissionComponent implements OnInit {

  data: Object = JSON.parse(localStorage.getItem('data'));

  FORMJSON = JSON.parse(localStorage.getItem('form'));
  SUBJSON = JSON.parse(localStorage.getItem('data'));
  

  private form: Object = this.FORMJSON;
  public submission: any;
  private formioRenderOptions: Object = {
    readOnly: true,
    renderMode: 'html',
    ignoreLayout: false 
   } 
  // public onSuccess: EventEmitter<object> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    
    
    if(this.SUBJSON != undefined){
      this.form = {
        components: this.FORMJSON.components,
      }
      this.submission = {data: this.SUBJSON}
    }else{
      Swal.fire({
        title: 'Please submit a Form',
        text: 'Please complete a form.',
        type: 'error',
        showConfirmButton: false,
        footer: '<a href = "./forms">Complete Form</a>',
        allowOutsideClick: false

      })
    } 
  }

  // onChange(){ 
  //   console.log(this.form)
  //   console.log(this.submission)
  //  }

  // onSubmit(submission: any, options: {noAlerts: true}) {

  //   Swal.fire({
  //     title: 'Saved locally!',
  //     text: JSON.stringify(submission.data),
  //     type: 'info',
  //     confirmButtonText: 'Close'
  //   })
  //   this.submission.data = submission.data;
  //   localStorage.setItem('data', JSON.stringify(submission.data));
   
  //   //this.submission.state = 'complete';
  

    
  // }

};


