import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuilderComponent } from './builder/builder.component';
import { FormsComponent } from './forms/forms.component';
import { ViewSubmissionComponent } from './forms/view-submission/view-submission.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './shared/auth.guard';



const routes: Routes = [
  {path: '', component: BuilderComponent,canActivate: [AuthGuard]},
  {path: 'builder', component: BuilderComponent,canActivate: [AuthGuard]},
  {path: 'forms', component: FormsComponent},
  {path: 'view-submission', component: ViewSubmissionComponent},
  {path: 'login',component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

