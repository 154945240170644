import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class CreateFormService {

  constructor(private firestore: AngularFirestore) { }

  createForm(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("company/5acb8a8ab719992aa7b1ce92/test")
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }
}
