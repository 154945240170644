import { Component } from '@angular/core';
import { AuthenticationService } from './shared/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'projectRoberta';

  constructor(public authenticationService: AuthenticationService) {}

  
}
