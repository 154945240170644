import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { CreateFormService } from '../shared/create-form.service'

import Swal from 'sweetalert2';
import { from } from 'rxjs';


@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss']
})


export class BuilderComponent implements OnInit {

  constructor(private createFormService: CreateFormService) {}

  FORMJSON = JSON.parse(localStorage.getItem('form'));


  private form: Object = {
    components: []
    };
    
  public options = Options ;

  ngOnInit() {
    if(this.FORMJSON != undefined){
      this.form = {
        components: this.FORMJSON.components,
      }
    }  
  }
  onChange(event) {

    localStorage.setItem('form',JSON.stringify(event.form));
    console.log(event.form);
  }
  saveForm() {
    Swal.fire({
      title: 'Saved!',
      text: 'This form has been saved, you can see what this form looks like by going to See Form',
      type: 'success',
      confirmButtonText: 'Close message',
      footer: '<a href = "./forms">See Form</a>'
    })
    let data = {data: JSON.stringify(this.FORMJSON.components)}
    this.createFormService.createForm(data).then(res => {
      /*do something here....maybe clear the form or give a success message*/
    });


  }

  clearForm() {
    localStorage.removeItem('form');
    this.form = {
      components: []
    }
  }

};

let Options = {

  readOnly: true,
  noAlerts: true,
  builder: {
    basic: false,
    advanced: false,
    data: false,
    layout: false,
    customBasic: {
      title: 'Components',
      default: true,
      weight: 0,
      components: {
        textfield: true,
        textarea: true,
        number: true,
        phoneNumber: true,
        email: true,
        checkbox: true,
        radio: true,
        select : true,
        datetime: true,
        modaledit: true,
        file: true,
        signature: true
      }
    },
    customLayout: {
      title: 'Layout',
      default: true,
      weight: 0,
      components: {
        panel: true,
        columns: true, 
        datagrid: true  
      }
    }
  },
  editForm: {
    textfield: [
      {key: 'display', components: [
        {key: 'widget.type', ignore: true},
        {key: 'errorLabel', ignore: true},
        {key: 'inputMask', ignore: true},
        {key: 'allowMultipleMasks', ignore: true},
        {key: 'prefix', ignore: true},
        {key: 'suffix', ignore: true},
        {key: 'customClass', ignore: true},
        {key: 'tabindex', ignore: true},
        {key: 'persistent', ignore: true},
        {key: 'protected', ignore: true},
        {key: 'mask', ignore: true},
        {key: 'disabled', ignore: true},
        {key: 'autofocus', ignore: true},
        {key: 'tableView', ignore: true},
        {key: 'alwaysEnabled', ignore: true}
      ]},
      {key: 'data', ignore: true},
      {key: 'validation', components: [
        {key: 'custom-validation-js', ignore: true},
        {key: 'json-validation-json', ignore: true}
      ]},
      {key: 'api', ignore: true},
      {key: 'conditional', components: [
        {key: 'customConditionalPanel', ignore: true},
      ]},
      {key: 'logic', components: [
        {key: '', components: []}
      ]}      
    ],
    textarea: [
      {key: 'display', components: [
        {key: 'editor', ignore: true},
        {key: 'isUploadEnabled', ignore: true},
        {key: 'prefix', ignore: true},
        {key: 'suffix', ignore: true},
        {key: 'customClass', ignore: true},
        {key: 'tabindex', ignore: true},
        {key: 'persistent', ignore: true},
        {key: 'protected', ignore: true},
        {key: 'tableView', ignore: true},
        {key: 'alwaysEnabled', ignore: true},
        // {key: 'disabled', ignore: true},
        {key: 'autofocus', ignore: true},
        {key: 'mask', ignore:true},
      ]},
      {key: 'data', components: []},
      {key: 'validation', components: [
        {key: 'custom-validation-js', ignore: true},
        {key: 'json-validation-json', ignore: true}
      ]},
      {key: 'api', ignore: true},
      {key: 'conditional', components: [
        {key: 'customConditionalPanel', ignore: true},
      ]},
      {key: 'logic', components: []}      
    ],
    number: [
      {key: 'display', components: [
        {key: 'customClass', ignore: true},
        {key: 'tabindex', ignore: true},
        {key: 'persistent', ignore: true},
        {key: 'protected', ignore: true},
        {key: 'mask', ignore: true},
        {key: 'disabled', ignore: true},
        {key: 'autofocus', ignore: true},
        {key: 'tableView', ignore: true},
        {key: 'alwaysEnabled', ignore: true}
      ]},
      {key: 'data', ignore: true},
      {key: 'validation', components: [
        {key: 'custom-validation-js', ignore: true},
        {key: 'json-validation-json', ignore: true}
      ]},
      {key: 'api', ignore: true},
      {key: 'conditional', components: [
        {key: 'customConditionalPanel', ignore: true},
      ]},
      {key: 'logic', components: []}      
    ],
    phoneNumber: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    email: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    checkbox: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    radio: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    select: [
      {key: 'display', components: []},
      {key: 'data', components: []},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    datetime: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    modaledit: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    file: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    signature: [
      {key: 'display', components: []},
      {key: 'data', ignore: true},
      {key: 'validation', components: []},
      {key: 'api', components: []},
      {key: 'conditional', components: []},
      {key: 'logic', components: []}      
    ],
    panel: []
  }
  
}

